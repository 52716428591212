import { CloseButton, LoadingOverlay, Paper, Tabs } from "@mantine/core";
import { IconReceipt, IconReceiptRefund } from "@tabler/icons";
import { useState } from "react";
import useSWR from "swr";
import { ScfRequestApi } from "../../apis";
import { Form } from "../form";
import { FormLayout } from "../layout";
import { TabInvoiceRequestFunding } from "./tab-invoice-funding";
import { TabRequestFunding } from "./tab-request-funding";
import { TabUseageLimitFunding } from "./tab-useage-limit-funding";

export const RequestFundingDetails = ({ setAction, action }: { action: any; setAction: (e: any) => void }) => {
  const [tabKey, setTabKey] = useState<"invoice" | "request" | "useage_limit">("invoice");

  const { data, isLoading } = useSWR<any>(
    `swr.scf.get.${action[1] && action[1]?.id}`,
    async () => action[1]?.id && (await ScfRequestApi.get(action[1] && action[1]?.id, "scf")),
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  if (isLoading)
    return (
      <div>
        <LoadingOverlay visible />
      </div>
    );
  else
    return (
      <FormLayout
        my={0}
        title="Санхүүжилтийн хүсэлт нэхэмжлэх"
        subTitle={`Санхүүжилтийн хүсэлт нэхэмжлэх`}
        extra={[<CloseButton onClick={() => setAction([])} key={0} />]}>
        <Form initialValues={data} onSubmit={() => {}}>
          {({ values, setFieldValue }) => {
            return (
              <Paper>
                <Tabs
                  value={tabKey}
                  onTabChange={(e: "invoice" | "request" | "useage_limit") => {
                    setTabKey(e);
                  }}>
                  <Tabs.List>
                    <Tabs.Tab value="invoice" icon={<IconReceipt size={18} />} py={"md"}>
                      Нэхэмжлэх
                    </Tabs.Tab>
                    <Tabs.Tab value="request" icon={<IconReceiptRefund size={18} />} py={"md"}>
                      Хүсэлт
                    </Tabs.Tab>
                  </Tabs.List>

                  <Tabs.Panel value="invoice" pt="xs">
                    <TabInvoiceRequestFunding data={data} />
                  </Tabs.Panel>

                  <Tabs.Panel value="request" pt="xs">
                    <TabRequestFunding programId={data?.programId} selectedData={data} setRequestedAmount={() => {}} formRef={null} />
                  </Tabs.Panel>

                  <Tabs.Panel value="useage_limit" pt="xs">
                    <TabUseageLimitFunding selectedData={data} />
                  </Tabs.Panel>
                </Tabs>
              </Paper>
            );
          }}
        </Form>
      </FormLayout>
    );
};
