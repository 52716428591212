import { Anchor, Button, Divider, Flex, Grid, Group, Input, LoadingOverlay, Modal, Paper, Tabs, Text, UnstyledButton, createStyles } from "@mantine/core";
import { IconCoins, IconSquareArrowLeft } from "@tabler/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import useSWR from "swr";
import { ScfRequestApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";
import { PageLayout } from "../layout";
import { PopoverText } from "../popover-text/popover-text";
import { ColumnType, Table } from "../table";
import { PayForm } from "./form";

export const ReceivedLendDetails = () => {
  const breadcrumbs = useBreadcrumb();
  const [tabKey, setTabKey] = useState<any>("1");
  const params = useParams();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const navigate = useNavigate();
  const [action, setAction] = useState<any[]>([]);
  const { theme } = useStyles();

  const { data, isLoading } = useSWR<any>(
    `swr.api.repayment.${params.id}`,
    async () => {
      if (params.id) {
        try {
          const res = await ScfRequestApi.get(params.id, "lbf");

          return res;
        } catch (error: any) {
          message.error(error?.message);
        }
      } else return null;
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  const columns = useHeader();

  const onClose = () => {
    setTabKey("2");
    setAction([]);
  };

  return (
    <div>
      <PageLayout
        breadcrumb={breadcrumbs}
        title={
          <Flex align="center" gap={"xs"}>
            <UnstyledButton onClick={() => navigate(-1)}>
              <IconSquareArrowLeft color={theme.colors.golomt[6]} />
            </UnstyledButton>
            <Text>
              Зээлийн данс:{" "}
              <Text component="span" color="golomt">
                {data?.accountNumber || "-"}
              </Text>
            </Text>
          </Flex>
        }
        extra={[
          <Button disabled={isLoading} onClick={() => setAction(["pay"])} leftIcon={<IconCoins size={"1.2rem"} />} key={0}>
            Төлөлт хийх
          </Button>,
        ]}>
        <Paper pos={"relative"}>
          <LoadingOverlay visible={isLoading} />
          <Text fw={600} fz={"lg"}>
            Зээлийн дансны мэдээлэл
          </Text>
          <Text fz={"sm"} color="dimmed">
            Таны хүсэлтийн дагуу санхүүжилт олгосон зээлийн дансны жагсаалт. Та санхүүжилтээ эргэн төлөхдөө уг дансанд төлнө үү.
          </Text>
          <Divider mt={"lg"} />
          <Tabs
            defaultValue={"1"}
            value={tabKey}
            onTabChange={(e) => {
              setTabKey(e);
            }}>
            <Tabs.List>
              <Tabs.Tab p={"lg"} value="1">
                Зээлийн дансны мэдээлэл
              </Tabs.Tab>
              <Tabs.Tab p={"lg"} value="2">
                Төлөлтийн түүх
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="1">
              <Paper my={"lg"}>
                <Grid>
                  <Grid.Col>
                    <Text fz={"lg"} fw={500}>
                      Харилцагчийн мэдээлэл
                    </Text>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Харилцагчийн CIF">
                      <Text fz={"sm"} color="golomt">
                        {user?.clientCode || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Татвар төлөгчийн дугаар">
                      <Text fz={"sm"} color="golomt">
                        {data?.requestedBusiness?.regNumber}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Харилцагчийн нэр">
                      <Text fz={"sm"} color="golomt">
                        {data?.accountName || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Партнерийн нэр">
                      <div>
                        <Text fz={"sm"} color="golomt" component="span">
                          # {data?.requestedBusiness?.partner?.refCode}
                        </Text>
                        <Text fz={"sm"} component="span">
                          , {data?.requestedBusiness?.partner?.businessName || "-"}
                        </Text>
                      </div>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Данс хамаарах бизнес (зээл авсан)">
                      <div>
                        <Text fz={"sm"} color="golomt" component="span">
                          # {data?.requestedBusiness?.refCode || "-"}
                        </Text>
                        <Text fz={"sm"} component="span">
                          , {data?.requestedBusiness?.profileName || "-"}
                        </Text>
                      </div>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Зээл хүссэн ажилтан">
                      <div>
                        <Text fz={"sm"} color="golomt" component="span">
                          {data?.requestedUser?.lastName ? data?.requestedUser?.lastName[0] + ". " : ""}
                          {data?.requestedUser?.firstName}
                        </Text>
                      </div>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Хөтөлбөрийн нэр">
                      <div>
                        <Text fz={"sm"} color="golomt" component="span">
                          # {data?.lbfProgram?.refCode || "-"}
                        </Text>
                        <Text fz={"sm"} component="span">
                          , {data?.lbfProgram?.name || "-"}
                        </Text>
                      </div>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Бүтээгдэхүүний нэр">
                      <div>
                        <Text fz={"sm"} color="golomt" component="span">
                          # {data?.lbfProduct?.refCode || "-"}
                        </Text>
                        <Text fz={"sm"} component="span">
                          , {data?.lbfProduct?.name || "-"}
                        </Text>
                      </div>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Нэхэмжлэх дугаар">
                      <Text fz={"sm"} color="golomt">
                        # {data?.invoice?.refCode || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Зээл олгосон дүн">
                      <Text fz={"sm"} color="golomt">
                        {currencyFormat(data?.invoice?.amountToPay || 0)}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Олголт хүлээн авсан данс">
                      <Text fz={"sm"} color="golomt">
                        {user?.currentBusiness?.type === "SUPPLIER" && (
                          <>
                            {data?.invoice?.supplierAccount.number}, {data?.invoice?.supplierAccount.name}
                          </>
                        )}
                        {user?.currentBusiness?.type === "BUYER" && (
                          <>
                            {data?.invoice?.buyerAccount.number}, {data?.invoice?.buyerAccount.name}
                          </>
                        )}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col>
                    <Text fz={"lg"} fw={500}>
                      Дансны мэдээлэл
                    </Text>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Зээлийн дансны дугаар">
                      <Text fz={"sm"} color="golomt">
                        {data?.accountNumber || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Зээлийн дансны нэр">
                      <Text fz={"sm"} color="golomt">
                        {data?.accountName || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Банк бүтээгдэхүүний нэр">
                      <Text fz={"sm"} color="golomt">
                        {data?.accountCategory || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Валют">
                      <Text fz={"sm"} color="golomt">
                        {data?.currency || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Данс нээсэн огноо">
                      <Text fz={"sm"} color="golomt">
                        {data?.openDate}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Данс хаагдах хугацаа">
                      <Text fz={"sm"} color="golomt">
                        {data?.maturityDate}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Хугацаа сараар">
                      <Text fz={"sm"} color="golomt">
                        {data?.periodInMonths || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Дараагийн өр төлөх хугацаа">
                      <Text fz={"sm"} color="golomt">
                        {data?.nextInstallmentDueDate ? dateTimeFormat(data?.nextInstallmentDueDate) : "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Хугацаа хэтэрсэн дүн">
                      <Text fz={"sm"} color="golomt">
                        {currencyFormat(data?.overdueAmount)}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Нийт үлдэгдэл">
                      <Text fz={"sm"} color="golomt">
                        {currencyFormat(data?.payOffAmount)}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                </Grid>
              </Paper>
            </Tabs.Panel>
            <Tabs.Panel value="2">
              <Table minWidth={2400} name="collateral.list" columns={columns} filters={{}} dataSource={data?.payments || []} />
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </PageLayout>

      <Modal
        centered
        opened={action[0] === "pay"}
        title={
          <Text fw={600} fz={"lg"}>
            Зээл төлөх
          </Text>
        }
        size={"70rem"}
        onClose={() => setAction([])}>
        <PayForm onClose={onClose} data={data} />
      </Modal>
    </div>
  );
};

const useStyles = createStyles({});

const useHeader = (): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  // {
  //   title: "",
  //   renderTitle: () => {
  //     return <Checkbox />;
  //   },
  //   render: (_r, index) => <Checkbox />,
  //   width: "30px",
  // },
  {
    title: " Гүйлгээний огноо",
    render: (record) => {
      return (
        <Text w="max-content">
          <Anchor># {record.refCode}</Anchor>
          <Text>{dateTimeFormat(record.createdAt)} </Text>
        </Text>
      );
    },
  },
  {
    title: "Харьцсан данс",
    render: (record) => {
      return (
        <Text w="max-content">
          <Anchor>{record.payerAccNum}</Anchor>
          <Text>{record.payerAccName}</Text>
        </Text>
      );
    },
  },
  {
    title: "Төлбөл зохих",
    render: (record) => {
      return (
        <Group position="right">
          <Text>{currencyFormat(record.firstAmount)}</Text>
        </Group>
      );
    },
  },
  {
    title: "Төлсөн дүн",
    render: (record) => {
      return (
        <Group position="right">
          <Text>{currencyFormat(record.amount)}</Text>
        </Group>
      );
    },
  },
  {
    title: "Үлдэгдэл",
    render: (record) => {
      return (
        <Group position="right">
          <Text>{currencyFormat(record.lastAmount)}</Text>
        </Group>
      );
    },
  },
  {
    title: "Гүйлгээний утга",
    render: (record) => (
      <Group position="right">
        <PopoverText>{record.description}</PopoverText>
      </Group>
    ),
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/",
    label: "Санхүүжилт",
  },
  {
    label: "Авсан санхүүжилт",
  },
  {
    label: "Зээлийн шугам ашигласан",
  },
];
