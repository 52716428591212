import { Flex, Grid, Input, Paper, Space, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { Tag } from "../tags";

export const LendDetailsInvoice = ({ action }: { action: any[] }) => {
  const { invoiceStatus, invoicePaymentStatus, invoiceOverdueStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const data = action[1] && { ...action[1] };

  return (
    <Paper py={"lg"}>
      <Text color="gray.8" fw={600}>
        Ерөнхий мэдээлэл
      </Text>
      <Space h={"lg"} />
      <Grid>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн код">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                # {data?.refCode || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх илгээсэн">
            <Text>
              <Text fz={"sm"} color="golomt" component="span" tt={"capitalize"}>
                {data?.senderUser?.lastName ? data?.senderUser?.lastName[0] + ". " : ""} {data?.senderUser?.firstName || "-"}{" "}
              </Text>
              <Text fz={"sm"} component="span">
                {data?.sentDate ? dateTimeFormat(data.sentDate) : "-"}
              </Text>
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх баталсан">
            <div>
              <Text component="span" fz={"sm"} color="golomt" tt={"capitalize"}>
                {data?.confirmedUser?.lastName ? data?.confirmedUser?.lastName[0] + ". " : ""} {data?.confirmedUser?.firstName || "-"}{" "}
              </Text>
              <Text fz={"sm"} component="span">
                {data?.confirmedDate ? dateTimeFormat(data.confirmedDate) : "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Баталсан дүн">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {currencyFormat(data?.confirmedAmount)}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Төлсөн дүн">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {currencyFormat(data?.paidAmount)}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн үлдэгдэл">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {currencyFormat(data?.amountToPay)}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх төлөв">
            <div>
              {Tag(
                invoiceStatus?.find((item: IReference) => item.code === data?.invoiceStatus)?.color || "gray",
                invoiceStatus?.find((item: IReference) => item.code === data?.invoiceStatus)?.name || "-",
              )}
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Төлбөрийн төлөв">
            <div>
              {Tag(
                invoicePaymentStatus?.find((item: IReference) => item.code === data?.paymentStatus)?.color || "gray",
                invoicePaymentStatus?.find((item: IReference) => item.code === data?.paymentStatus)?.name || "-",
              )}
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хугацаа хэтрэлтийн төлөв">
            <div>
              {Tag(
                invoiceOverdueStatus?.find((item: IReference) => item.code === data?.overdueStatus)?.color || "gray",
                invoiceOverdueStatus?.find((item: IReference) => item.code === data?.overdueStatus)?.name || "-",
              )}
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Төлбөрийн нөхцөл">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {data?.paymentTermDesc || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх төлөх огноо">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {data?.paymentDate ? dateTimeFormat(data?.paymentDate) : "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн баримт">
            <div>
              <Text component="span" fz={"sm"}>
                _{data?.refCode}_.pdf
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col>
          <Text color="gray.8" fw={600}>
            Нийлүүлэгч талын мэдээлэл
          </Text>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Партнерийн нэр">
            <Flex gap={8} align={"center"}>
              <Text component="span" fz={"sm"}>
                {data?.senderBusiness?.partner?.businessName || "-"},
              </Text>
              <Text fz={"sm"} component="span" color="golomt">
                # {data?.senderBusiness?.partner?.refCode || "-"},
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Татвар төлөгчийн дугаар">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {data?.senderBusiness?.regNumber || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нийлүүлэгч бизнес">
            <Flex gap={8}>
              <Text component="span" fz={"sm"}>
                {data?.senderBusiness?.profileName || "-"},
              </Text>
              <Text component="span" fz={"sm"} color="golomt">
                # {data?.senderBusiness?.refCode || "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нийлүүлэгчийн данс">
            <Flex gap={8} align={"end"}>
              <Text component="span" fz={"sm"} color="golomt">
                {data?.senderAcc?.number || "-"},
              </Text>
              <Text component="span" fz={"sm"}>
                {data?.senderAcc?.name || "-"}, {data?.senderAcc?.bankName || "-"},
              </Text>
              <Text component="span" fz={"xs"} color="gray">
                ({data?.senderAcc?.shortName || "-"})
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Борлуулалтын захиалга">
            <Flex gap={8}>
              <Text component="span" fz={"sm"} color="golomt">
                {data?.orderSalesCode || "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Санхүүгийн ажилтан">
            <Flex gap={8}>
              <Text component="span" fz={"sm"} color="golomt" tt={"capitalize"}>
                {data?.senderFinUser?.lastName ? data?.senderFinUser?.lastName[0] + ". " : ""} {data?.senderFinUser?.firstName || "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col>
          <Text color="gray.8" fw={600}>
            Худалдан авагчийн мэдээлэл
          </Text>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Партнерийн нэр">
            <Flex gap={8}>
              <Text fz="sm">{data?.receiverBusiness?.partner?.businessName || "-"}</Text>
              <Text fz="sm" color="golomt">
                #{data?.receiverBusiness?.partner?.refCode || "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Татвар төлөгчийн дугаар">
            <Flex gap={8}>
              <Text component="span" fz={"sm"} color="golomt">
                {data?.receiverBusiness?.regNumber || "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Худалдан авагч бизнес">
            <Flex align={"center"} gap={8}>
              <Text fz="sm">{data?.receiverBusiness?.profileName || "-"},</Text>
              <Text fz="sm" color="golomt">
                #{data?.receiverBusiness?.refCode || "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Худалдан авагчийн данс">
            <Flex columnGap={8} align={"end"} wrap={"wrap"}>
              <Text component="span" fz={"sm"} color="golomt">
                {data?.receiverAcc?.number || "-"}
              </Text>
              <Text component="span" fz={"sm"}>
                , {data?.receiverAcc?.name || "-"}, {data?.receiverAcc?.bankName || "-"}{" "}
              </Text>
              <Text component="span" fz={"xs"} color="gray">
                ({data?.receiverAcc?.shortName || "-"})
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Худалдан авалтын захиалга">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {data?.purchaseOrder?.refCode || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Санхүүгийн ажилтан">
            <div>
              <Text component="span" fz={"sm"} tt={"capitalize"} c={"golomt"}>
                {data?.receiverFinUser?.lastName ? data?.receiverFinUser?.lastName[0] + ". " : ""} {data?.receiverFinUser?.firstName || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        {/* <Grid.Col>
          <Code>
            <pre>{JSON.stringify(action, null, 2)}</pre>
          </Code>
        </Grid.Col> */}
      </Grid>
    </Paper>
  );
};
