import { ActionIcon, Anchor, Group, Paper, Text, Tooltip } from "@mantine/core";
import { IconCircleMinus, IconFileText } from "@tabler/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ProgramparticipantApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";

export const ProgramBuyerAnchor = ({ data }: { data?: any }) => {
  const params = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState<any>({ programId: params.id });
  const { networkPenaltyTypes, programParticipantStatus, networkDiscountTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    programParticipantStatus,
    networkPenaltyTypes,
    networkDiscountTypes,
    onClick: (key, item) => {
      switch (key) {
        case "details":
          break;
        case "status":
          break;

        default:
          break;
      }
    },
  });

  return (
    <Paper>
      <Text c={"gray.6"} fw={500} py="lg">
        Нэхэмжлэх санхүүжүүлэх үеийн нөхцөл
      </Text>
      <Table minWidth={2400} name="program.participant.list" columns={columns} filters={filters} loadData={(data) => ProgramparticipantApi.list(data)} />
    </Paper>
  );
};

const useHeader = ({
  onClick,
  programParticipantStatus,
  networkDiscountTypes,
  networkPenaltyTypes,
}: {
  onClick: (e1: string, e2: any) => void;
  programParticipantStatus: IReference[];
  networkDiscountTypes: IReference[];
  networkPenaltyTypes: IReference[];
}): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    dataIndex: "description",
    render: (record) => {
      return (
        <Group noWrap>
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <ActionIcon variant="light" color={"golomt"} onClick={() => onClick("details", record)}>
              <IconFileText />
            </ActionIcon>
          </Tooltip>

          <Tooltip label="Төлөв" position="bottom">
            <ActionIcon variant="light" color={"golomt"} onClick={() => onClick("status", record)}>
              <IconCircleMinus />
            </ActionIcon>
          </Tooltip>
        </Group>
      );
    },
  },
  {
    title: "ТТД",
    dataIndex: "sku",
    render: (record) => {
      return <Text w={"max-content"}>{record.business?.regNumber || "-"}</Text>;
    },
  },
  {
    title: "Партнерийн нэр",
    dataIndex: "sku",
    render: (record) => {
      return record.business?.partner ? (
        <Text w={"max-content"}>
          <Anchor># {record.business?.partner?.refCode} ,</Anchor>
          <Text>{record.business?.partner?.businessName || "-"}</Text>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "SME бизнесийн нэр",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text w={"max-content"}>
          <Anchor># {record.business?.refCode} ,</Anchor>
          <Text>{record.business?.profileName || "-"}</Text>
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөр дэх статус",
    dataIndex: "sku",
    render: (record) => {
      return Tag(
        programParticipantStatus?.find((item: IReference) => item.code === record.participantStatus)?.color || "gray",
        programParticipantStatus?.find((item: IReference) => item.code === record.participantStatus)?.name || "-",
      );
    },
  },
  {
    title: "Бүсийн нэр",
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Text w={"max-content"}>{record?.areaRegion?.name || "-"}</Text>
        </div>
      );
    },
  },
  {
    title: "Чиглэл",
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Text w={"max-content"}>{record?.areaDirection?.name || "-"}</Text>
        </div>
      );
    },
  },
  {
    title: "Төлбөрийн нөхцөл",
    dataIndex: "sku",
    render: (record) => {
      return record?.paymentTerm ? (
        <Text w={"max-content"}>
          <Text color={"golomt"}>#{record?.paymentTerm?.refCode}</Text>
          <Text color="dimmed">{record?.paymentTerm?.description}</Text>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Нийлүүлэгчээс олгосон авлага лимит",
    dataIndex: "sku",
    width: 120,
    render: (record) => <Text align="end">{currencyFormat(record?.debtLimitAmount || 0)}</Text>,
  },
];
