import { FileInput } from "@mantine/core";
import { IconCloudUpload } from "@tabler/icons";
import React from "react";
import { MediaApi } from "../../apis";
import { message } from "../../utils/Message";

export type IFile = {
  uploading: boolean;
  url?: string;
  file: Blob | null;
};

type Props = {
  value?: string | undefined | null;
  error?: string;
  onChange?: (file: IFile) => void;
  size?: number;
  radius?: number;

  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  nullable?: boolean;
  styles?: any;
};

export function FileUpload({ onChange, error, value, name, label, placeholder, disabled = false, nullable, styles }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = React.useState<any>(null);

  const onFileUpload = (blob: Blob | null) => {
    if (!blob) return;
    if (blob.size < 10485760) {
      (async () => {
        try {
          const form = new FormData();
          form.append("file", blob);

          const res = await MediaApi.uploadFile(form);

          if (onChange)
            onChange({
              file: blob,
              url: res.url,
              uploading: false,
            });

          setFile({
            file: blob,
            url: res.url,
            uploading: false,
          });
        } catch (err: any) {
          setFile(null);
          message.error(err.message);
          console.log(err);
        }
      })();
    } else {
      message.error("Файлын хэмжээ 1 mib - с хэтэрсэн байна!");
    }

    setFile({
      file: blob,
      uploading: true,
    });
  };

  React.useEffect(() => {
    value &&
      setFile({
        file: null,
        url: value,
        uploading: false,
      });
  }, [value]);

  return (
    <>
      <FileInput
        icon={<IconCloudUpload size={18} />}
        styles={styles}
        onChange={onFileUpload}
        required={nullable ? false : true}
        error={error}
        label={label}
        disabled={disabled}
      />
    </>
  );
}
