import { createStyles, Grid, Input, Paper, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { currencyFormat } from "../../utils";

export const FinanceLimit = ({ data }: { data: any }) => {
  const { theme } = useStyle();
  const { programTypes } = useSelector((state: { general: IGeneral }) => state.general);

  console.log("types: ", programTypes);

  return (
    <Paper mt="lg">
      <Grid>
        <Grid.Col>
          <Text c={"gray.6"} fw={500}>
            Санхүүжилтийн хөтөлбөрийн лимит
          </Text>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хөтөлбөрийн төрөл">
            <Text fw={500} fz={"sm"} color={theme.primaryColor}>
              {programTypes.find((item: any) => item.code === data.type)?.name}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хөтөлбөрийн нэр">
            <Text fw={500} fz={"sm"} color={theme.primaryColor}>
              {data?.refCode ? "# " + data.refCode + ", " : "-"}{" "}
              <Text component="span" color="gray.8">
                {data?.name || ""}
              </Text>
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Бүтээгдэхүүний нэр">
            <Text fw={500} fz={"sm"} color={theme.primaryColor}>
              {data?.product.refCode ? "# " + data.product.refCode + ", " : "-"}{" "}
              <Text component="span" color="gray.8">
                {data?.product.name || ""}
              </Text>
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Анкорын партнер нер">
            <Text fw={500} fz={"sm"} color={theme.primaryColor}>
              # {data?.anchor?.partner?.refCode || "-"}
              <Text color={"gray.8"} component="span">
                , {data?.anchor?.partner?.businessName || "-"}
              </Text>
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Анкор бизнесийн нэр">
            <Text fw={500} fz={"sm"} color={theme.primaryColor}>
              # {data?.anchor?.refCode || "-"}
              <Text component="span" color="gray.8">
                , {data?.anchor?.profileName || "-"}
              </Text>
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Санхүүжилт хүсэх ажилтан ">
            <Text fw={500} fz={"sm"} color={theme.primaryColor}>
              {data?.anchor?.finUser?.lastName ? data?.anchor?.finUser?.lastName[0] + ". " : ""} {data?.anchor?.finUser?.firstName || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хөтөлбөрийн лимит ">
            <Text fw={500} fz={"sm"} color={theme.primaryColor}>
              {currencyFormat(data.programLimit)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    </Paper>
  );
};

const useStyle = createStyles((theme) => ({}));
