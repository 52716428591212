import { Badge, Flex, Grid, Input, Paper, Space, Switch, Text } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";
import { currencyFormat } from "../../utils";
import { dateFormat, dateTimeFormat } from "../../utils/date";

export const LbfRequestTabSupplier = ({ action, values }: { action: any[]; values: { requestedAmount: number } }) => {
  const data: null | any = action[1] && action[1];
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const booleanIcon = (min: number, mid: number, max: number) => {
    switch (true) {
      case min > mid || mid > max: {
        return <IconX size={20} color="red" />;
      }
      case min <= mid && mid <= max: {
        return <IconCheck size={20} color="green" />;
      }

      default: {
        return "-";
      }
    }
  };

  return (
    <Paper py={"lg"}>
      <Text color="gray.8" fw={600}>
        Хүсэлтийн ерөнхий мэдээлэл
      </Text>
      <Space h={"lg"} />
      <Grid>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Код, огноо цаг">
            <div>
              <Text component="span" fz={"sm"} color="gray.8">
                {"Авто үүснэ"}
              </Text>
              <Text component="span" fz={"sm"}>
                , {dateFormat(new Date())}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хүсэлт гаргасан ажилтан">
            <Text color="golomt" fz={"sm"} tt={"capitalize"}>
              {data?.invoice?.senderUser?.lastName ? data?.invoice?.senderUser?.lastName[0] + ". " : ""}
              {data?.invoice?.senderUser?.firstName || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хүсэлтийн төлөв">
            <div>
              <Badge color="gray" variant="outline">
                {"Хүлээгдэж буй"}
              </Badge>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Харилцагчийн код">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {user?.clientCode || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Харилцагчийн нэр">
            <div>
              <Text component="span" fz={"sm"} color="golomt" tt={"capitalize"}>
                # {data?.requestedBusiness?.partner?.refCode || "-"}
              </Text>
              <Text fz={"sm"} component="span">
                , {data?.requestedBusiness?.partner?.businessName || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Татвар төлөгчийн дугаар">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {data?.requestedBusiness?.regNumber || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хүсэлт хамаарах бизнес">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                # {data?.requestedBusiness?.refCode || "-"}
              </Text>
              <Text component="span" fz={"sm"}>
                , {data?.requestedBusiness?.profileName || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хөтөлбөрийн нэр">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                # {data?.lbfProgram?.refCode || "-"}
              </Text>
              <Text component="span" fz={"sm"}>
                , {data?.lbfProgram?.name || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Бүтээгдэхүүний нэр">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                # {data?.lbfProduct?.refCode || "-"}
              </Text>
              <Text component="span" fz={"sm"}>
                , {data?.lbfProduct?.name || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col>
          <Text color="gray.8" fw={600}>
            Нэхэмжлэхийн шалгуур хангах байдал
          </Text>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Санхүүжилт хүсэх дүн">
            <Text fz={"sm"} fw={500} color="golomt">
              {currencyFormat(data?.requestedAmount)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх төлөх огноо">
            <Text fz={"sm"} color="golomt">
              {data?.invoice?.paymentDate ? dateTimeFormat(data?.invoice?.paymentDate) : "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Зээл төлөх огноо">
            <Text fz={"sm"} color="golomt">
              {data?.invoice?.paymentDate ? dateTimeFormat(data?.invoice?.paymentDate) : "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Санхүүжилтийн хэмжээ шалгуур">
            <Flex align="center" gap={"xs"}>
              {booleanIcon(
                ((data?.invoice?.amountToPay || 0) * (data?.lbfProduct?.minOfInv || 0)) / 100,
                values.requestedAmount,
                ((data?.invoice?.amountToPay || 0) * (data?.lbfProduct?.maxOfInv || 0)) / 100,
              )}
              <Text component="span" fz={"sm"}>
                {currencyFormat(((data?.invoice?.amountToPay || 0) * (data?.lbfProduct?.minOfInv || 0)) / 100)} -{" "}
                {currencyFormat(((data?.invoice?.amountToPay || 0) * (data?.lbfProduct?.maxOfInv || 0)) / 100)}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн үлдэгдэл">
            <Flex align="center" gap={"xs"}>
              <IconCheck size={"1.2rem"} color="green" />
              <Text component="span" fz={"sm"}>
                {currencyFormat(data?.invoice?.amountToPay)}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх Tenor">
            <div>
              <Flex gap="xs" align="center">
                {booleanIcon(data?.minTenor, Math.abs(Number(dayjs(data?.invoice?.paymentDate).diff(dayjs(), "day"))), data?.maxTenor)}
                <Text fz="sm" fw={500}>
                  {data?.minTenor} - {data?.maxTenor}
                </Text>
              </Flex>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх хугацаа">
            <Flex align="center" gap={"xs"}>
              {dayjs(new Date()).diff(data?.invoice?.confirmedDate, "days") > 0 ? (
                <IconCheck size={"1.2rem"} color="green" />
              ) : (
                <IconX size={"1.2rem"} color="red" />
              )}
              <Text component="span" fz={"sm"}>
                {data?.invoice?.confirmedDate ? dayjs(new Date()).diff(data?.invoice?.confirmedDate, "days") : "-"} хоног
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Буцаан дуудах эсэх">
            <Flex pt={"4px"}>
              <Switch
                checked={data?.recourseTerm || false}
                onChange={() => {}}
                offLabel={<Text fz={"10px"}>Үгүй</Text>}
                onLabel={<Text fz={"10px"}>Тийм</Text>}
              />
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Буцаан дуудах огноо">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {dayjs(data?.invoice?.paymentDate)
                  .add(data?.lbfProduct?.recourseDays || 0, "days")
                  .format("YYYY-MM-DD hh:mm")}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Олголт хүлээн авах данс">
            {data?.invoice?.receiverAcc ? (
              <div>
                <Text component="span" fz={"sm"} color="golomt">
                  {data?.invoice?.receiverAcc?.number}
                </Text>
                <Text component="span" fz={"sm"}>
                  , {data?.invoice?.receiverAcc?.name}, {data?.invoice?.receiverAcc?.bankName},{" "}
                  <Text component="span" color="gray" fz={"xs"}>
                    ({data?.invoice?.receiverAcc?.shortName})
                  </Text>
                </Text>
              </div>
            ) : (
              <div>-</div>
            )}
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    </Paper>
  );
};
