import { ActionIcon, Avatar, Drawer, Flex, Group, Tabs, Text, Tooltip } from "@mantine/core";
import { IconFilter, IconInfoSquare, IconPrinter } from "@tabler/icons";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ScfRequestApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { RequestFundingLendLineList } from "../../components/request-funding-lend-line/list";
import { RequestFundingDetails } from "../../components/request-funding/details";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

export const RequestsFunding = () => {
  const breadcrumbs = useBreadcrumb();
  const { currencies, scfRequestStatus, fundingInstructionStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [filters, setFilters] = useState<any>({ query: "", financeType: "LBF" });
  const [action, setAction] = useState<any[]>([]);

  const columns = useHeader({
    currencies,
    scfRequestStatus,
    Tag,
    fundingInstructionStatus,
    onClick: (key, record) => {
      setAction([key, record]);
    },
  });

  return (
    <PageLayout
      title="Санхүүжих хүсэлт"
      subTitle="Эрт санхүүжилтийн хүсэлт"
      breadcrumb={breadcrumbs}
      extra={[
        <Group key={0}>
          <ActionIcon variant="light" size={36} color="golomt">
            <IconPrinter size={25} />
          </ActionIcon>
          <ActionIcon variant="light" size={36} color="golomt">
            <IconFilter size={25} />
          </ActionIcon>
        </Group>,
      ]}>
      <Tabs
        onTabChange={(e) => {
          setFilters({ ...filters, financeType: e });
        }}
        defaultValue="LBF">
        <Tabs.List>
          <Tabs.Tab py={"md"} value="LBF">
            Зээлийн шугам ашиглах
          </Tabs.Tab>
          <Tabs.Tab py={"md"} value="SCF">
            Нэхэмжлэх барьцаалах
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="SCF">
          {filters.financeType === "SCF" && (
            <div>
              <Table
                minWidth={2400}
                name={`buyer.requests.list`}
                columns={columns}
                filters={{ financeType: "SCF" }}
                loadData={(data) => ScfRequestApi.list(data!)}
              />
            </div>
          )}
        </Tabs.Panel>
        <Tabs.Panel value="LBF">{filters.financeType === "LBF" && <RequestFundingLendLineList />}</Tabs.Panel>
      </Tabs>

      <Drawer
        styles={{ drawer: { overflowY: "scroll" } }}
        zIndex={100}
        opened={action[0] === "detail"}
        onClose={() => setAction([])}
        withCloseButton={false}
        position="right"
        padding="xl"
        size="80rem">
        <RequestFundingDetails action={action} setAction={setAction} />
      </Drawer>
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  currencies,
  scfRequestStatus,
  Tag,
  fundingInstructionStatus,
}: {
  onClick: (key: string, record: any) => void;
  currencies: IReference[];
  scfRequestStatus: any;
  Tag: any;
  fundingInstructionStatus: IReference[];
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    render: (record) => (
      <Tooltip label="Дэлгэрэнгүй" position="bottom">
        <ActionIcon variant="filled" onClick={() => onClick("detail", record)} color="golomt">
          <IconInfoSquare />
        </ActionIcon>
      </Tooltip>
    ),
  },
  {
    title: "Хүсэлтийн код",
    width: "10px",
    render: (record) => (
      <Text color="gray.7" fw={500} w={"max-content"}>
        # {record?.refCode || "-"}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх",
    render: (record) => (
      <Text fw={500} color="indigo" w={"max-content"}>
        # {record?.invRefCode || "-"}
      </Text>
    ),
  },
  {
    title: "Хүсэлт төлөв",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {Tag(
          scfRequestStatus?.find((item: IReference) => item.code === record?.requestStatus)?.color,
          scfRequestStatus?.find((item: IReference) => item.code === record?.requestStatus)?.name || "-",
        )}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх үлдэгдэл",
    render: (record) => (
      <Text fw={500} color="gray.7" align="end">
        {currencyFormat(record?.invAmountToPay || 0)}
      </Text>
    ),
  },
  {
    title: "Хүсэлтийн дүн",
    render: (record) => (
      <Text fw={500} color="gray.7" align="end">
        {currencyFormat(record?.requestedAmount || 0)}
      </Text>
    ),
  },
  {
    title: "Валют",
    render: (record) => (
      <Text color="gray.7" fw={500} w={"max-content"}>
        {currencies?.find((f: any) => f?.code === record?.program?.currency)?.name || "Төгрөг"}
      </Text>
    ),
  },
  {
    title: "Хоног",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.financePeriod}
      </Text>
    ),
  },
  {
    title: "Санхүүжилтийн шимтгэл",
    render: (record) => (
      <Text fw={500} color="gray.7" align="end">
        {currencyFormat(record?.scfFeeAmount || 0)}
      </Text>
    ),
  },
  {
    title: "Банк шимтгэл",
    render: (record) => (
      <Text fw={500} color="gray.7" align="end">
        {currencyFormat(record?.bankFeeAmount || 0)}
      </Text>
    ),
  },
  // {
  //   title: "Бусад шимтгэл",
  //   render: (record) => (
  //     <Text fw={500} color="gray.7" w="max-content">
  //       -
  //     </Text>
  //   ),
  // },
  {
    title: "Нийлүүлэгч бизнес",
    render: (record) => {
      return record?.supplierBusiness ? (
        <Text fw={500} color="gray.7" w="max-content">
          {record?.supplierBusiness?.profileName} <Text color="indigo"> # {record?.supplierBusiness?.refCode}</Text>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Нийлүүлэгч",
    render: (record) => {
      return record?.supplierBusiness?.partner ? (
        <Text fw={500} color="gray.7" w="max-content">
          {record?.supplierBusiness?.partner?.businessName} <Text color="indigo"> # {record?.supplierBusiness?.partner?.refCode}</Text>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Хөтөлбөр нэр",
    render: (record) =>
      record?.program ? (
        <Text w="max-content">
          <Text fw={500} color="gray.7" w="max-content">
            {record?.program?.name || "-"}
          </Text>
          <Text color="indigo"># {record?.program?.refCode || "-"}</Text>
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Бүтээгдэхүүн",
    render: (record) =>
      record?.product ? (
        <Text w="max-content">
          <Text fw={500} color="gray.7" w="max-content">
            {record?.product?.name || "-"}
          </Text>
          <Text color="indigo"># {record?.product?.refCode || "-"}</Text>
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Нэхэмжлэх огноо",
    render: (record) => (
      <Text color="gray.7" fw={500}>
        {dateTimeFormat(record?.invCreatedAt)}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх баталсан",
    render: (record) => (
      <Text color="gray.7" fw={500}>
        {dateTimeFormat(record?.invConfirmedDate)}
      </Text>
    ),
  },
  {
    title: "Худалдан авагч бизнес",
    render: (record) => {
      return record?.buyerBusiness ? (
        <Text fw={500} color="gray.7" w="max-content">
          {record?.buyerBusiness?.profileName || "-"} <Text color="indigo"> # {record?.buyerBusiness?.refCode}</Text>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Худалдан авагч",
    render: (record) => {
      return record?.buyerBusiness ? (
        <Text fw={500} color="gray.7" w="max-content">
          {record?.buyerBusiness?.partner?.businessName || "-"} <Text color="indigo"> # {record?.buyerBusiness?.partner?.refCode}</Text>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Хүсэлт илгээсэн",
    render: (record) =>
      record?.requestedUser ? (
        <Flex gap={8} align="center">
          <Avatar src={record?.requestedUser?.avatar} />
          <Text fw={500} color="gray.7" w="max-content" tt={"capitalize"}>
            {record?.requestedUser?.lastName ? record?.requestedUser?.lastName[0] + ". " : ""} {record?.requestedUser?.firstName || "-"}
          </Text>
        </Flex>
      ) : (
        "-"
      ),
  },
  // {
  //   title: "Funding instruction",
  //   render: (record) => (
  //     <Text fw={500} color="gray.7" w="max-content">
  //       {Tag(
  //         fundingInstructionStatus.find((item: IReference) => item.code === record?.fundingInstructionStatus)?.color || "gray",
  //         fundingInstructionStatus.find((item: IReference) => item.code === record?.fundingInstructionStatus)?.name || "Үүсээгүй",
  //       )}
  //     </Text>
  //   ),
  // },
  {
    title: "Банк ажилтан",
    render: (record) =>
      record?.respondedUser ? (
        <Flex gap={8} align="center">
          <Avatar src={record?.respondedUser?.avatar} />
          <Text fw={500} color="gray.7" w="max-content" tt={"capitalize"}>
            {record?.respondedUser?.lastName ? record?.respondedUser?.lastName[0] + ". " : ""} {record?.respondedUser?.firstName || "-"}
          </Text>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Олгох дүн",
    render: (record) => (
      <Flex align="center" justify="flex-end">
        <Text fw={500} color="gray.7" w="max-content">
          {currencyFormat(record?.approvedAmount || 0)}
        </Text>
      </Flex>
    ),
  },
  // {
  //   title: "Татах шимтгэл",
  //   render: (record) => (
  //     <Text fw={500} color="indigo">
  //       Татах шимтгэл
  //     </Text>
  //   ),
  // },
  // {
  //   title: "Татах шимтгэл 2",
  //   render: (record) => (
  //     <Text fw={500} color="indigo">
  //       Татах шимтгэл 2
  //     </Text>
  //   ),
  // },
  // {
  //   title: "Татах шимтгэл 3",
  //   render: (record) => (
  //     <Text fw={500} color="indigo">
  //       Татах шимтгэл 3
  //     </Text>
  //   ),
  // },
  {
    title: "Шийдвэрлэх огноо",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {dayjs(record?.responseDate).format("YYYY-MM-DD HH:mm")}
      </Text>
    ),
  },
  {
    title: "Олгох огноо",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.toDisburseDate ? dayjs(record?.toDisburseDate).format("YYYY-MM-DD HH:mm") : "-"}
      </Text>
    ),
  },
  {
    title: "Эргэн төлөх дүн",
    render: (record) => (
      <Flex justify="flex-end">
        <Text fw={500} color="gray.7" w="max-content">
          {currencyFormat(record?.repaymentAmount || 0)}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Эргэн төлөх огноо",
    render: (record) => (
      <Text fw={500} color="gray.7" lineClamp={1}>
        {record?.repaymentDate ? dateTimeFormat(record?.repaymentDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Эргэн төлөх нэхэмжлэх",
    render: (record) => (
      <Text fw={500} color="indigo">
        {record?.repaymentInv?.refCode || "-"}
      </Text>
    ),
  },
  {
    title: "Олгосон огноо",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.disbursedDate ? dateTimeFormat(record?.disbursedDate) : "-"}
      </Text>
    ),
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/",
    label: "Санхүүжилт",
  },
  {
    label: "Санхүүжих хүсэлт",
  },
];
