import { Select } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  placeholder: string;
  disabled?: boolean;
  onChange?: (value: string | null) => void;
  noError?: boolean;
  options: { value: string; label: string }[] | any;
  clearable?: boolean;
  required?: boolean;
  searchable?: boolean;
  onSearchChange?: (e: string) => void | any;
  readOnly?: boolean;
  rightSection?: any;
  SelectItem?: any;
};

export function SelectField({
  name,
  label,
  placeholder,
  noError,
  onChange: onChangeValue,
  options = [],
  disabled,
  clearable,
  required,
  searchable,
  onSearchChange = () => {},
  readOnly,
  rightSection,
  SelectItem = undefined,
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <Select
      rightSection={rightSection}
      readOnly={readOnly}
      searchable={searchable}
      label={label}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onSearchChange={onSearchChange}
      onChange={(value) => {
        onChange && onChange(value);
        onChangeValue && onChangeValue(value);
      }}
      error={error}
      itemComponent={SelectItem}
      required={required}
      clearable={clearable}
      data={options}
      style={{ flex: 1 }}
      styles={{
        error: {
          display: noError ? "none" : "block",
        },
        wrapper: {
          marginBottom: noError && error && 0,
        },
      }}
    />
  );
}
