import { Anchor, Badge, Box, Checkbox, Divider, Flex, Grid, Input, LoadingOverlay, Paper, Text } from "@mantine/core";
import { IconCheck, IconFile, IconX } from "@tabler/icons";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { Form } from "../form";

const schema = yup.object({
  requestedAmount: yup.number()?.moreThan(0, "0-с их үнэ байна!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  termsOfContract: yup.boolean().required("Заавал бөглөнө!").oneOf([true], "Заавал бөглөнө!"),
  contractFile: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IBuyerLedCreate = {
  programId: string;
  invId: string;
  requestedAmount: number;
  contractFile: string;
  termsOfContract: boolean;
};

export function TabRequestFunding({
  selectedData,
  formRef,
  setRequestedAmount,
  programId,
}: {
  selectedData: any;
  formRef: any;
  programId: string;
  setRequestedAmount: (e: number) => void;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = React.useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = React.useState<IBuyerLedCreate>({
    requestedAmount: selectedData?.amountToPay || 0,
    contractFile: "",
    invId: selectedData?.id,
    programId: programId,
    termsOfContract: false,
  });

  const { user } = useSelector((state: any) => state.auth);
  const { scfRequestTypes, scfRequestStatus, currencies, productBuyerFeeRules, productBuyerPenaltyTypes, invoiceOverdueStatus } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );

  const onSubmit = async (values: IBuyerLedCreate | any) => {};

  const booleanIcon = (min: number, mid: number, max: number) => {
    switch (true) {
      case min > mid || mid > max: {
        return <IconX size={20} color="red" />;
      }
      case min <= mid && mid <= max: {
        return <IconCheck size={20} color="green" />;
      }

      default: {
        return "-";
      }
    }
  };

  const checkRequimentIcon = (val1: number, val2: number) => {
    if (val1 < val2) {
      return <IconX size={20} color="red" />;
    } else return <IconCheck size={20} color="green" />;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const totalFee = ({ minFee, maxFee, values }: { minFee: number; maxFee: number; values: any }) => {
    // Тооцсон шимтгэл
    let TootssonFee =
      user?.currentBusiness?.type === "SUPPLIER"
        ? dayjs(selectedData?.paymentDate).diff(dayjs(), "day") *
            (values?.requestedAmount || 0) *
            ((selectedData?.program?.product?.suppFee || 0) / (selectedData?.program?.product?.suppCalculateDay || 0)) || 0
        : (selectedData?.program?.product?.buyerCalculateDay || 0) * (values.requestedAmount || 0) * dayjs(selectedData?.paymentDate).diff(dayjs(), "day");

    // Гүйлгээний шимтгэл
    let guilgeeFee =
      user?.currentBusiness?.type === "SUPPLIER" ? selectedData?.program?.product?.suppTrxFee || 0 : selectedData?.program?.product?.buyerTrxFee || 0;

    // Өгөгдлийн хураамж
    let ogogdlinHuraamj =
      user?.currentBusiness?.type === "SUPPLIER" ? selectedData?.program?.product?.suppAppFee || 0 : selectedData?.program?.product?.buyerAppFee || 0;

    // Олголтын шимтгэл`
    let olgoltingFee =
      user?.currentBusiness?.type === "SUPPLIER"
        ? ((values?.requestedAmount || 0) * (selectedData?.program?.product?.suppDisbursementFee || 0)) / 100 || 0
        : ((values?.requestedAmount || 0) * (selectedData?.program?.product?.buyerDisbursementFee || 0)) / 100 || 0;

    let total = TootssonFee + guilgeeFee + ogogdlinHuraamj + olgoltingFee;

    if (maxFee <= total) return maxFee;
    else if (minFee >= total) return minFee;
    else return total;
  };

  return (
    <Form ref={formRef} initialValues={data && data} onSubmit={onSubmit} validationSchema={schema}>
      {({ values, setFieldValue, errors }) => {
        return (
          <Grid>
            <LoadingOverlay visible={loading} />
            <Grid.Col span={12}>
              <Divider
                label={
                  <Text color="golomt" fw={600} size={"md"}>
                    Хүсэлтийн мэдээлэл
                  </Text>
                }
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хүсэлтийн код:">
                <div>
                  <Text fz="sm" fw={500}>
                    <Text color="golomt"># {selectedData?.refCode || "-"}</Text>
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хүсэлтийн төрөл:">
                <div>
                  <Text fz="sm" fw={500}>
                    {scfRequestTypes?.find((f: any) => f?.code === selectedData?.type)?.name}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хүсэлт гаргасан бизнес:">
                <div>
                  <Text fz="sm" fw={500}>
                    {selectedData?.type === "SUPPLIER" ? (
                      <Flex gap={8} align="center">
                        <Text>{selectedData?.requestedBusiness?.profileName || "-"}</Text>
                        <Text color="golomt"># {selectedData?.requestedBusiness?.refCode || "-"}</Text>
                      </Flex>
                    ) : (
                      <Flex gap={8} align="center">
                        <Text>{selectedData?.invReceiverBusiness?.profileName || "-"}</Text>
                        <Text color="golomt"># {selectedData?.invReceiverBusiness?.refCode || "-"}</Text>
                      </Flex>
                    )}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хүсэлтийн огноо:">
                <div>
                  <Text fz="sm" fw={500}>
                    <Text color="gray">{selectedData?.createdAt ? dateTimeFormat(selectedData?.createdAt) : "-"}</Text>
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хүсэлт гаргасан хэрэглэгч:">
                <div>
                  <Text fz="sm" fw={500}>
                    {selectedData?.invSenderUser?.lastName ? selectedData?.invSenderUser?.lastName[0] + ". " : ""} {selectedData?.invSenderUser?.firstName}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хүсэлтийн төлөв:">
                <div>
                  <Badge color={scfRequestStatus.find((item: any) => item.code === selectedData?.requestStatus)?.color}>
                    {scfRequestStatus.find((item: any) => item.code === selectedData?.requestStatus)?.name || "Түр төлөв"}
                  </Badge>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хөтөлбөрийн нэр:">
                <Flex gap="xs" wrap={"wrap"} rowGap={"0"}>
                  <Text fz="sm" fw={500}>
                    # {selectedData?.program?.refCode || "-"},
                  </Text>
                  <Text color="dimmed" fz="sm" fw={500}>
                    {selectedData?.program?.name || "-"}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Бүтээгдэхүүний нэр:">
                <Flex columnGap="xs" wrap={"wrap"}>
                  <Text fz="sm" fw={500}>
                    # {selectedData?.product?.refCode || "-"}
                  </Text>
                  <Text color="dimmed" fz="sm" fw={500}>
                    {selectedData?.product?.name || "-"}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хүсэлтийн дүн">
                <Text color="dimmed" fz="sm" fw={500}>
                  {currencyFormat(selectedData?.requestedAmount || 0)}
                  {/* {currencyFormat(selectedData?.invAmountToPay || 0)} */}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Санхүүжилтийн валют:">
                <div>
                  <Text fz="sm" fw={500}>
                    {currencies.find((mk: any) => mk.code === selectedData?.currency)?.name || "-"}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Санхүүжилтийн доод, дээд хэмжээ:">
                <Flex style={{ alignItems: "center" }} gap="xs">
                  {booleanIcon(selectedData?.finMinAmount, values?.requestedAmount, selectedData?.finMaxAmount)}
                  {selectedData?.finMinAmount} - {selectedData?.finMaxAmount}
                </Flex>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Нэхэмжлэх доод үлдэгдэл:">
                <Flex align="center" gap={8}>
                  {checkRequimentIcon(selectedData?.invAmountToPay || 0, selectedData?.minInvBalance || 0)}
                  <Text fz="sm" fw={500}>
                    {currencyFormat(selectedData?.minInvBalance || 0)}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Нэхэмжлэх Tenor:">
                <Flex gap="xs" align="center">
                  {booleanIcon(selectedData?.minTenor, Math.abs(Number(dayjs(selectedData?.invPaymentDate).diff(dayjs(), "day"))), selectedData?.maxTenor)}
                  <Text fz="sm" fw={500}>
                    {selectedData?.minTenor} - {selectedData?.maxTenor}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Санхүүжилт авах хугацаа /хоног/:">
                <Text fz="sm" fw={500}>
                  {selectedData?.financePeriod || 0} хоног
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Шимтгэл тооцсон хувь /хоногт/:">
                <Text fz="sm" fw={500}>
                  {selectedData?.calculatedFeePercent} %
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Тооцсон шимтгэл:">
                <Text fz="sm" fw={500}>
                  {currencyFormat(selectedData?.calculatedFeeAmount || 0)}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Өгөгдлийн хураамж:">
                <Text fz="sm" fw={500}>
                  {currencyFormat(selectedData?.appFee || 0)}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Олголтын шимтгэл:">
                <Text fz="sm" fw={500}>
                  {currencyFormat(selectedData?.disbursementFee || 0)}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Нийт шимтгэлийн дүн:">
                <div>
                  <Text fz="sm" fw={500}>
                    {currencyFormat(selectedData?.totalScfFeeAmount || 0)}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Шимтгэл суутгах дүрэм:">
                <Text fz="sm" fw={500}>
                  {productBuyerFeeRules?.find((f: any) => f?.code === selectedData?.feeRule)?.name || "-"}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Шимтгэлийн доод дүн:">
                <Flex gap="xs" align="center">
                  {selectedData?.feeAcceptable ? <IconCheck size={20} color="green" /> : <IconX size={20} color="red" />}
                  <Text fz="sm" fw={500}>
                    {user?.currentBusiness?.type === "SUPPLIER" ? selectedData?.product?.suppMinFee || 0 : selectedData?.product?.buyerMinFee || 0}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Эргэн төлөх хугацаа:">
                <div>
                  <Text fz="sm" fw={500}>
                    {selectedData?.invPaymentDate ? dateTimeFormat(selectedData?.invPaymentDate) : "-"}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Буцаан дуудах эсэх:">
                <div>
                  {selectedData?.recourseTerm ? (
                    <Badge variant="filled" color="blue" radius={4}>
                      Тийм
                    </Badge>
                  ) : (
                    <Badge variant="filled" color="gray" radius={4}>
                      Үгүй
                    </Badge>
                  )}
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Буцаан дуудах огноо:">
                <div>
                  <Text fz="sm" fw={500}>
                    {selectedData?.recourseDate ? dateTimeFormat(selectedData.recourseDate) : "-"}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хугацаа хэтрэх алданги:">
                <Text fz="sm" fw={500}>
                  {selectedData?.penaltyPercent || "-"} %
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Алдангийн арга:">
                <Text fz="sm" fw={500}>
                  {productBuyerPenaltyTypes.find((item: IReference) => item.code === selectedData?.penaltyType)?.name || "-"}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Нэхэмжлэхийн хугацаа:">
                <div>
                  <Text fz="sm" fw={500}>
                    {selectedData?.invConfirmedDays} хоног
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хугацааны шалгуур:">
                <Flex align="center" gap="xs">
                  {selectedData?.daysAcceptable ? <IconCheck size={20} color="green" /> : <IconX size={20} color="red" />}
                  <Text fz="sm" fw={500}>
                    {selectedData?.minDays} - {selectedData?.maxDays}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хугацаа хэтрэлт:">
                <Flex gap="xs" align="center">
                  {selectedData?.overDueStatusAcceptable ? <IconCheck size={20} color="green" /> : <IconX size={20} color="red" />}
                  <Text fz="sm" fw={500} color="dimmed">
                    {invoiceOverdueStatus?.find((item: IReference) => item.code === selectedData?.invOverdueStatus)?.name || "-"}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Санхүүжилт авах данс:">
                <div>
                  <Text fz="sm" fw={500}>
                    {selectedData?.requestedBusinessAcc?.name || "-"}, {selectedData?.requestedBusinessAcc?.number || "-"}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хавсралт">
                <Flex columnGap={"xs"} direction={"column"}>
                  {selectedData?.requestFiles.length > 0 ? (
                    selectedData?.requestFiles?.map((item: any, index: number) => {
                      return (
                        <Anchor href={item?.url} key={index}>
                          <Text fz="sm" fw={500} w={"max-content"} truncate>
                            <Flex align={"center"} gap={"xs"}>
                              <IconFile size={18} /> {item?.url ? `Хавсралт файл_${index + 1}` : "файл байхгүй."}
                            </Flex>
                          </Text>
                        </Anchor>
                      );
                    })
                  ) : (
                    <Flex align={"center"} gap={"8px"}>
                      <Text fz="sm" fw={500} color="gray">
                        Хавсралт файл байхгүй.
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4} />

            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Гэрээ баталгаажилт">
                <Box my={"xs"}>
                  <Checkbox checked={true} readOnly label="Тийм" />
                </Box>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Гэрээ баталгаажилт:">
                <Paper pt={"4px"}>
                  <Badge color="green" variant="filled">
                    Баталгаажсан
                  </Badge>
                </Paper>
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
}
