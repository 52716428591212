import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const status = async (data: { programId: string; smeBusinessId: string; isActive: boolean }) => {
  return httpRequest.put(`/api/program/participant/status`, data);
};

export const list = async (data: any) => {
  return httpRequest.get("/api/program/participant", data);
};
