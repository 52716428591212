/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, ActionIcon, Avatar, Box, Checkbox, CloseButton, Flex, Grid, Group, Input, NumberInput, Paper, Select, Text } from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import { useDebouncedState } from "@mantine/hooks";
import { IconCurrencyTugrik, IconSearch, IconTrash } from "@tabler/icons";
import dayjs from "dayjs";
import { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { FinanceableApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  refCode: string;
  color?: string | undefined;
}

const FILTER_INIT = {
  financeType: "SCF",
  programId: undefined,
  buyerName: undefined,
  buyerRegNumber: undefined,
  startDate: null,
  endDate: null,
  minAmount: null,
  maxAmount: null,
};

export const InvoicePossibleSupplier = ({
  selectedInvs = [],
  setSelectedInvs,
  tableRef,
  hasFilter,
}: {
  selectedInvs: any[];
  setSelectedInvs: (e: any) => void;
  tableRef: any;
  hasFilter: boolean;
}) => {
  const { currencies, invoicePaymentStatus, invoiceStatus, invoiceOverdueStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const { id: programId } = useParams();
  const [filters, setFilters] = useState<any>({ ...FILTER_INIT, programId: programId });

  const [buyerQuery, setBuyerQuery] = useDebouncedState("", 800);
  const [buyerSelects, setBuyerSelects] = useState<any[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [regNumberQuery, setRegNumberQuery] = useDebouncedState("", 800);
  const [regNumberSelects, setRegNumberSelects] = useState<any[]>([]);

  const selectRest = {
    allowDeselect: true,
    icon: <IconSearch size={18} />,
    clearable: true,
    searchable: true,
  };

  const buyerSelectGet = async () => {
    let res = await FinanceableApi.buyerSelect({ limit: 10, query: buyerQuery, isName: true });
    setBuyerSelects(
      res?.map((item: any) => {
        return {
          label: item?.profileName || "",
          value: item?.id || "",
          refCode: item?.refCode || "",
        };
      }) || [],
    );
  };

  const regNumberSelectGet = async () => {
    let res = await FinanceableApi.buyerSelect({ limit: 10, query: regNumberQuery, isName: false });
    setRegNumberSelects(
      res?.map((item: any) => {
        return {
          label: item?.regNumber || "",
          value: item?.id || "",
          refCode: (item?.refCode || "") + " " + item.profileName,
        };
      }) || [],
    );
  };

  const columns = useHeader({
    currencyFormat,
    selectedInvs,
    onClick: (key, record) => {
      switch (key) {
        case "select_all":
          setSelectedInvs([...record]);
          break;
        case "unselect_all":
          setSelectedInvs([]);
          break;
        case "detail":
          break;
        case "select":
          setSelectedInvs([...selectedInvs, record]);
          break;
        case "remove":
          setSelectedInvs(selectedInvs.filter((f: any) => f.id !== record.id));
          break;
        default:
          break;
      }
    },
    invoicePaymentStatus,
    currencies,
    invoiceStatus,
    invoiceOverdueStatus,
  });

  const SelectItemBuyer = forwardRef<HTMLDivElement, ItemProps>(({ label, refCode, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" color="dimmed">
            # {refCode}
          </Text>
        </div>
      </Group>
    </div>
  ));

  const SelectStatus = forwardRef<HTMLDivElement, ItemProps>(({ label, color, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Paper radius={6} w={"6px"} h={"6px"} bg={color || "blue"} withBorder></Paper>
        <Text size="sm">{label}</Text>
      </Group>
    </div>
  ));

  useEffect(() => {
    buyerSelectGet();
  }, [buyerQuery]);

  useEffect(() => {
    regNumberSelectGet();
  }, [regNumberQuery]);

  return (
    <Paper>
      <Accordion unstyled value={hasFilter ? "open" : ""}>
        <Accordion.Item value="open">
          <Accordion.Panel>
            <Box py={"lg"}>
              <Grid>
                <Grid.Col md={6} lg={4} xl={3}>
                  <Select
                    value={filters.buyerBusinessId || null}
                    label="Худалдан авагч хайх"
                    onChange={(e) => {
                      setFilters({ ...filters, buyerBusinessId: e });
                    }}
                    itemComponent={SelectItemBuyer}
                    nothingFound="Худалдан авагч байхгүй."
                    onSearchChange={(e) => {
                      setBuyerQuery(e);
                    }}
                    placeholder="Сонгоно уу"
                    data={buyerSelects}
                    {...selectRest}
                  />
                </Grid.Col>
                <Grid.Col md={6} lg={4} xl={3}>
                  <Select
                    value={filters.buyerBusinessId || null}
                    label="Татвар төлөгчийн дугаараар хайх"
                    onChange={(e) => {
                      setFilters({ ...filters, buyerBusinessId: e });
                    }}
                    itemComponent={SelectItemBuyer}
                    nothingFound="Худалдан авагч байхгүй."
                    onSearchChange={(e) => {
                      setBuyerQuery(e);
                    }}
                    placeholder="Сонгоно уу"
                    data={regNumberSelects}
                    {...selectRest}
                  />
                </Grid.Col>
                <Grid.Col md={6} lg={4} xl={3}>
                  <Input.Wrapper label="Нэхэмжлэхийн төлөв сонгох">
                    <Select
                      clearable
                      onChange={(e) => setFilters({ ...filters, paymentStatus: e })}
                      value={filters.paymentStatus || ""}
                      itemComponent={SelectStatus}
                      placeholder="Сонгоно уу"
                      data={[
                        {
                          label: "Хүлээгдэж буй",
                          value: "PENDING",
                          color: "gray",
                        },
                        {
                          label: "Хуваасан",
                          value: "DIVIDED",
                          color: "indigo",
                        },
                      ]}
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4} xl={3}>
                  <Input.Wrapper label="Нэхэмжлэх төлөх огнооны интервал">
                    <DateRangePicker
                      value={[filters?.startDate || null, filters?.endDate || null]}
                      onChange={(e) => {
                        setFilters({ ...filters, startDate: e[0] && e[0], endDate: e[1] && e[1] });
                      }}
                      placeholder="Эхлэх огноо / Дуусах огноо"
                      clearable
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4} xl={3}>
                  <Input.Wrapper label="Үнийн дүнгийн интервалаар (Доод дүн) ">
                    <NumberInput
                      onChange={(e) => setFilters({ ...filters, minAmount: e })}
                      value={filters?.minAmount || undefined}
                      rightSection={
                        <CloseButton
                          mr={"6px"}
                          onClick={() => setFilters({ ...filters, minAmount: undefined })}
                          variant="transparent"
                          size={"sm"}
                          color="gray.6"
                        />
                      }
                      icon={<IconCurrencyTugrik size={18} stroke="1.5" />}
                      parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                      formatter={(value: any) => (!Number.isNaN(parseFloat(value)) ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : "")}
                      placeholder="0.00"
                    />
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col md={6} lg={4} xl={3}>
                  <Input.Wrapper label="Үнийн дүнгийн интервалаар (Дээд дүн) ">
                    <NumberInput
                      onChange={(e) => setFilters({ ...filters, maxAmount: e })}
                      value={filters?.maxAmount || undefined}
                      rightSection={
                        <CloseButton
                          mr={"6px"}
                          onClick={() => setFilters({ ...filters, maxAmount: undefined })}
                          variant="transparent"
                          size={"sm"}
                          color="gray.6"
                        />
                      }
                      icon={<IconCurrencyTugrik size={18} stroke="1.5" />}
                      parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                      formatter={(value: any) => (!Number.isNaN(parseFloat(value)) ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : "")}
                      placeholder="0.00"
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4} xl={3}>
                  <Input.Wrapper label=" ">
                    <ActionIcon onClick={() => setFilters({ ...FILTER_INIT, programId: programId })} size={36} variant="light" color="golomt">
                      <IconTrash />
                    </ActionIcon>
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>
            </Box>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <Table
        ref={tableRef}
        loadData={async (data) => await FinanceableApi.list(data!)}
        name={`product.SUPPLIER_LED.list`}
        columns={columns}
        filters={hasFilter ? { ...filters, financeType: "SCF" } : { ...FILTER_INIT, programId: programId }}
        minWidth={2400}
      />
    </Paper>
  );
};

const useHeader = ({
  onClick,
  invoicePaymentStatus,
  currencies,
  invoiceStatus,
  currencyFormat,
  selectedInvs = [],
  invoiceOverdueStatus,
}: {
  onClick: (key: string, record: any) => void;
  invoicePaymentStatus: IReference[];
  currencies: IReference[];
  invoiceStatus: IReference[];
  currencyFormat: any;
  selectedInvs: any[];
  invoiceOverdueStatus: any[];
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: <div></div>,
    renderTitle: (data) => {
      return (
        <>
          <Checkbox
            checked={selectedInvs?.length > 0}
            onChange={(e) => {
              if (!e.target.checked) {
                onClick("unselect_all", []);
              } else onClick("select_all", data?.rows || []);
            }}
          />
        </>
      );
    },
    render: (record) => (
      <div>
        <Checkbox
          checked={selectedInvs?.some((item: any) => item.id === record.id)}
          onChange={() => {
            if (!selectedInvs?.some((item: any) => item.id === record.id)) onClick("select", record);
            else onClick("remove", record);
          }}
        />
      </div>
    ),
  },
  {
    title: "Нэхэмжлэх #",
    render: (record) => (
      <Flex direction="column">
        <Text color="golomt" fw={500} w="max-content">
          # {record?.refCode || "-"}
        </Text>
        <Text fw={500} color="gray.7" w="max-content">
          {dayjs(record?.createdAt).format("YYYY-MM-DD HH:mm")}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Нийлүүлэгч бизнес",
    render: (record) =>
      record?.type === "SALES" ? (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.senderBusiness?.profileName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.senderBusiness?.refCode || "-"}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.receiverBusiness?.profileName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.receiverBusiness?.refCode || "-"}
          </Text>
        </Flex>
      ),
  },
  {
    title: "Нийлүүлэгч",
    render: (record) =>
      record?.type === "SALES" ? (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.senderBusiness?.partner?.businessName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.senderBusiness?.partner?.refCode || "-"}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.receiverBusiness?.partner?.businessName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.receiverBusiness?.partner?.refCode || "-"}
          </Text>
        </Flex>
      ),
  },
  {
    title: "Худалдан авагч бизнес",
    render: (record) =>
      record?.type !== "SALES" ? (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.senderBusiness?.profileName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.senderBusiness?.refCode}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.receiverBusiness?.profileName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.receiverBusiness?.refCode || "-"}
          </Text>
        </Flex>
      ),
  },
  {
    title: "Худалдан авагч партнер",
    render: (record) =>
      record?.type !== "SALES" ? (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.senderBusiness?.partner?.businessName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.senderBusiness?.partner?.refCode || "-"}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.receiverBusiness?.partner?.businessName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.receiverBusiness?.partner?.refCode || "-"}
          </Text>
        </Flex>
      ),
  },
  {
    title: "Баталсан дүн",
    render: (record) => (
      <Flex align="center" justify="flex-end">
        <Text fw={500} color="gray.7" w="max-content">
          {currencyFormat(record?.confirmedAmount || 0)}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Төлсөн дүн",
    render: (record) => (
      <Flex align="center" justify="flex-end">
        <Text color="gray.7" fw={500} w={"max-content"}>
          {record?.paidAmount ? currencyFormat(record?.paidAmount || 0) : "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Валют",
    render: (record) => (
      <Text color="gray.7" fw={500}>
        {currencies?.find((f: any) => f?.code === record?.program?.currency)?.name || "Төгрөг"}
      </Text>
    ),
  },
  {
    title: "Үлдэгдэл",
    render: (record) => (
      <Flex align="center" justify="flex-end">
        <Text color="gray.7" fw={500} w={"max-content"}>
          {record?.amountToPay ? currencyFormat(record?.amountToPay || 0) : "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Төлбөр статус",
    render: (record) =>
      Tag(
        invoicePaymentStatus.find((item: IReference) => item.code === record?.paymentStatus)?.color || "blue",
        invoicePaymentStatus.find((item: IReference) => item.code === record?.paymentStatus)?.name || "-",
      ),
  },
  {
    title: "Хугацаа хэтрэлт",
    render: (record) =>
      Tag(
        invoiceOverdueStatus.find((item: IReference) => item.code === record?.overdueStatus)?.color || "blue",
        invoiceOverdueStatus.find((item: IReference) => item.code === record?.overdueStatus)?.name || "-",
      ),
  },
  {
    title: "Хугацаа",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.remainingDays}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх төлөх огноо",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.paymentDate ? dateTimeFormat(record?.paymentDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх баталсан",
    render: (record) => (
      <Flex gap="xs" align="center">
        <Avatar radius={100} src={record?.confirmedUser?.avatar} />
        <Flex direction="column" gap="xs">
          <Text fw={500} color="golomt">
            {record?.confirmedUser?.lastName ? record?.confirmedUser?.lastName[0] + ". " : ""} {record?.confirmedUser?.firstName || "-"}
          </Text>
        </Flex>
      </Flex>
    ),
  },
  {
    title: "Нэхэмжлэх статус",
    render: (record) => (
      <>
        {record?.invoiceStatus
          ? Tag(
              invoiceStatus.find((item: IReference) => item.code === record?.invoiceStatus)?.color || "blue",
              invoiceStatus.find((item: IReference) => item.code === record?.invoiceStatus)?.name || "-",
            )
          : "-"}
      </>
    ),
  },
  {
    title: "Илгээсэн ажилтан",
    render: (record) =>
      record?.senderUser ? (
        <Flex gap="xs" align="center">
          <Avatar radius={100} src={record?.senderUser?.avatar} />
          <Flex direction="column" gap="xs">
            <Text fw={500} color="golomt" w={"max-content"} tt={"capitalize"}>
              {record?.senderUser?.lastName ? record?.senderUser?.lastName[0] + ". " : ""} {record?.senderUser?.firstName || "-"}
            </Text>
          </Flex>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Борлуулалтын захиалга",
    render: (record) =>
      record?.salesOrder ? (
        <Text fw={500} color="golomt">
          # {record?.salesOrder?.refCode || "-"}
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Худалдан авалтын захиалга",
    render: (record) =>
      record?.purchaseOrder ? (
        <Text fw={500} color="golomt">
          # {record?.purchaseOrder?.refCode || "-"}
        </Text>
      ) : (
        "-"
      ),
  },
];
