import { Anchor, Avatar, Badge, Box, CloseButton, Divider, Flex, Group, Input, LoadingOverlay, Paper, SimpleGrid, Tabs, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { RepaymentApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { EmailNotify } from "../../components/repayment/email-notify";
import { PromiseHistory } from "../../components/repayment/promise-history";
import { RememberHistory } from "../../components/repayment/remember-history";
import { ColumnType, Table } from "../../components/table";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";
import { Tag } from "./../../components/tags";

export const ReturnedDetails = ({ action, setAction }: { action: any[]; setAction: any }) => {
  const {
    currencies,
    productSuppFeeRules,
    repaymentStatus = [],
    programRepaymentRules = [],
    repaymentPaymentStatus,
    repaymentOverDueStatus = [],
    paymentMethods = [],
  } = useSelector((state: { general: IGeneral }) => state.general);

  const { data, isLoading } = useSWR<any>(
    "swr.RepaymentApi.get",
    async () => {
      try {
        if (action[1]) {
          const res = await RepaymentApi.get(action[1].id!);
          return res;
        } else return {};
      } catch (error: any) {
        error?.message && message.error(error?.message);
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  const { productBuyerPenaltyTypes, productSuppPenaltyTypes, transactionTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    onClick: async (key, record) => {
      switch (key) {
        case "detail":
          setAction(["detail", record]);
          break;

        case "pay":
          setAction(["pay", record]);
          break;
        default:
          break;
      }
    },
    Tag,
    repaymentStatus,
    currencies,
    productSuppFeeRules,
    programRepaymentRules,
    repaymentPaymentStatus,
    repaymentOverDueStatus,
    paymentMethods,
    transactionTypes,
  });

  return (
    <PageLayout
      title={
        <Text>
          Буцаан дуудсан нэхэмжлэх{" "}
          <Text color="golomt" component="span">
            # {data?.refCode || "-"}
          </Text>
        </Text>
      }
      subTitle="Буцаан дуудсан эргэн төлөлтийн нэхэмжлэх"
      extra={[
        <div key={0}>
          <CloseButton
            onClick={() => {
              setAction([]);
            }}
          />
        </div>,
      ]}>
      <LoadingOverlay visible={isLoading} />
      <Tabs defaultValue={"1"}>
        <Tabs.List>
          <Tabs.Tab py={"md"} value="1">
            Үндсэн мэдээлэл
          </Tabs.Tab>
          <Tabs.Tab py={"md"} value="2">
            Төлөлтийн түүх
          </Tabs.Tab>
          <Tabs.Tab py={"md"} value="3">
            Сануулга түүх
          </Tabs.Tab>
          <Tabs.Tab py={"md"} value="4">
            И-мэйл сануулга
          </Tabs.Tab>
          <Tabs.Tab py={"md"} value="5">
            Амлалт түүх
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="1">
          {/* <RepaymentDetail id={action[1]?.id as string} /> */}
          <Box>
            <Paper>
              <Paper py={"lg"}>
                <Text fw={600} fz={"lg"}>
                  Эргэн төлөлт хариуцагч тал
                </Text>
              </Paper>
              <SimpleGrid
                breakpoints={[
                  { minWidth: 980, cols: 3, spacing: "md" },
                  { minWidth: 755, cols: 2, spacing: "sm" },
                  { maxWidth: 600, cols: 1, spacing: "sm" },
                ]}>
                <Input.Wrapper label="Партнерийн нэр:">
                  <Text fz={"sm"}>
                    <Text color="golomt" component="span">
                      # {data?.payerBusiness?.partner?.refCode}
                    </Text>{" "}
                    {", "}
                    {data?.payerBusiness?.partner?.businessName}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.payerBusiness?.regNumber || "-"}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Бизнес:">
                  <Text fz={"sm"}>
                    <Text color="golomt" component="span">
                      # {data?.payerBusiness?.refCode}
                    </Text>{" "}
                    {", "}
                    {data?.payerBusiness?.profileName}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Нэхэмжлэх код:">
                  <Text color="golomt" fz={"sm"}>
                    # {data?.invRefCode}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Эргэн төлөх сүүлийн хугацаа:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.repaymentDate ? dateTimeFormat(data?.repaymentDate) : "-"}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Хүлээж буй хугацаа:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.remainingDays >= 0 ? (
                      <Text fw={500} color="indigo" w={"max-content"}>
                        {data?.remainingDays} хоног
                      </Text>
                    ) : (
                      <Text fw={500} color="red" w={"max-content"}>
                        {Math.abs(data?.remainingDays)} хоног хэтэрсэн байна.
                      </Text>
                    )}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Зогсоох огноо:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.restrictDate ? dateTimeFormat(data?.restrictDate) : "-"}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Буцаан дуудах эсэх:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.recourseTerm ? (
                      <Badge color="green" size="sm" variant="outline">
                        Тийм
                      </Badge>
                    ) : (
                      <Badge color="red" size="sm" variant="outline">
                        Үгүй
                      </Badge>
                    )}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Эргэн төлөх дүрэм:">
                  <Text color="golomt" fz={"sm"}>
                    {programRepaymentRules.find((mk: any) => mk?.code === data?.repaymentRule)?.name || "-"}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Эргэн төлөх дүн:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.repaymentAmount ? currencyFormat(data?.repaymentAmount) : "-"}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Төлсөн дүн:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.paidAmount ? currencyFormat(data?.paidAmount) : "-"}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Төлбөрийн үлдэгдэл:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.amountToPay ? currencyFormat(data?.amountToPay) : "-"}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Алданги дүрэм:">
                  {data?.type === "SUPPLIER" ? (
                    <Text color="golomt" fz={"sm"}>
                      {productBuyerPenaltyTypes.find((item: IReference) => item.code === data?.penaltyType)?.name || "-"}
                    </Text>
                  ) : (
                    <Text color="golomt" fz={"sm"}>
                      {productSuppPenaltyTypes.find((item: IReference) => item.code === data?.penaltyType)?.name || "-"}
                    </Text>
                  )}
                </Input.Wrapper>
                <Input.Wrapper label="Алданги:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.penaltyPercent} %
                  </Text>
                </Input.Wrapper>
                <div></div>
                <Input.Wrapper label="Эргэн төлөлтийн нэхэмжлэх төлөв:">
                  <Text color="golomt" fz={"sm"}>
                    {Tag(
                      repaymentStatus?.find((item: IReference) => item?.code === data?.repaymentStatus)?.color || "",
                      repaymentStatus?.find((item: IReference) => item?.code === data?.repaymentStatus)?.name || "-",
                    )}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Төлбөрийн төлөв:">
                  <div>
                    {data?.paymentStatus
                      ? Tag(
                          repaymentPaymentStatus.find((item: IReference) => item.code === data?.paymentStatus)?.color || "indigo",
                          repaymentPaymentStatus.find((item: IReference) => item.code === data?.paymentStatus)?.name || "",
                        )
                      : "-"}
                  </div>
                </Input.Wrapper>
                <Input.Wrapper label="Хугацаа хэтрэлтийн төлөв:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.overdueStatus >= 0 ? (
                      <Text fw={500} color="gray.7" w="max-content">
                        {Tag(
                          repaymentOverDueStatus.find((item: IReference) => item.code === data?.overdueStatus)?.color || "indigo",
                          repaymentOverDueStatus.find((item: IReference) => item.code === data?.overdueStatus)?.name || "",
                        )}
                      </Text>
                    ) : (
                      <Text fw={500} color="gray.7" w="max-content">
                        {Tag(
                          repaymentOverDueStatus.find((item: IReference) => item.code === data?.overdueStatus)?.color || "indigo",
                          repaymentOverDueStatus.find((item: IReference) => item.code === data?.overdueStatus)?.name || "",
                        )}
                      </Text>
                    )}
                  </Text>
                </Input.Wrapper>
              </SimpleGrid>
              <Divider my={"lg"} />
              <Text fw={600} fz={"lg"} my={"lg"}>
                ДАНСНЫ МЭДЭЭЛЭЛ
              </Text>
              <SimpleGrid
                breakpoints={[
                  { minWidth: 980, cols: 3, spacing: "md" },
                  { minWidth: 755, cols: 2, spacing: "sm" },
                  { maxWidth: 600, cols: 1, spacing: "sm" },
                ]}>
                <Input.Wrapper label="Эргэн төлөлт авах банк:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.receiverAcc?.bankName}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Эргэн төлөлт авах данс:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.receiverAcc?.number}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Эргэн төлөлт авах дансны нэр:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.receiverAcc?.name}{" "}
                    <Text component="span" color="gray.7">
                      ( {data?.receiverAcc?.shortName || "-"} )
                    </Text>
                  </Text>
                </Input.Wrapper>
              </SimpleGrid>
              <Divider my={"lg"} />
              <Text fw={600} fz={"lg"} my={"lg"}>
                САНХҮҮЖИЛТ АВАГЧИЙН МЭДЭЭЛЭЛ
              </Text>
              <SimpleGrid
                breakpoints={[
                  { minWidth: 980, cols: 3, spacing: "md" },
                  { minWidth: 755, cols: 2, spacing: "sm" },
                  { maxWidth: 600, cols: 1, spacing: "sm" },
                ]}>
                <Input.Wrapper label="Эргэн төлөлт татах банк:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.payerAcc?.bankName}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Эргэн төлөлт татах данс:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.payerAcc?.number}
                  </Text>
                </Input.Wrapper>
                <Input.Wrapper label="Эргэн төлөлт татах дансны нэр:">
                  <Text color="golomt" fz={"sm"}>
                    {data?.payerAcc?.name}{" "}
                    <Text component="span" color="gray.7">
                      ( {data?.payerAcc?.shortName || "-"} )
                    </Text>
                  </Text>
                </Input.Wrapper>
              </SimpleGrid>
              {/* <Code>
                <pre>{JSON.stringify(data, null, 2)}</pre>
              </Code> */}
            </Paper>
          </Box>
        </Tabs.Panel>
        <Tabs.Panel value="2">
          {action[1] && (
            <Table
              name="payment.history.list"
              columns={columns}
              filters={{ repaymentId: action[1]?.id }}
              minWidth={2400}
              loadData={(data) => RepaymentApi.history(data!)}
            />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="3">{action[1] && <RememberHistory action={action} />}</Tabs.Panel>
        <Tabs.Panel value="4">{action[1] && <EmailNotify action={action} />}</Tabs.Panel>
        <Tabs.Panel value="5">{action[1] && <PromiseHistory action={action} />}</Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  programRepaymentRules,
  repaymentPaymentStatus,
  repaymentOverDueStatus,
  repaymentStatus,
  paymentMethods,
  Tag,
  transactionTypes,
}: {
  onClick: (key: string, record: any) => void;
  currencies: IReference[];
  productSuppFeeRules: any;
  programRepaymentRules: any;
  repaymentPaymentStatus: IReference[];
  repaymentOverDueStatus: any;
  repaymentStatus: IReference[];
  paymentMethods: IReference[];
  Tag: any;
  transactionTypes: IReference[];
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
  },
  {
    title: "Огноо, цаг",
    render: (record) => (
      <Text color="dimmed" w="max-content">
        {dateTimeFormat(record.createdAt)}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх дугаар",
    render: (record) => <Anchor># {record?.trxRefCode || "-"}</Anchor>,
  },
  {
    title: "Гүйлгээний лавлах",
    render: (record) => <Text w={"max-content"}>{record?.trxDesc || "-"}</Text>,
  },
  {
    title: "Нэхэмжлэх - эхний үлдэгдэл",
    render: (record) => (
      <Group position="right">
        <Text w="max-content" ta="right">
          {currencyFormat(record?.firstAmount || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Гүйлгээ - Төлбөрийн дүн",
    render: (record) => (
      <Group position="right">
        <Text w="max-content" ta="right">
          {currencyFormat(record?.trxAmount || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Нэхэмжлэх - эцсийн үлдэгдэл",
    render: (record) => (
      <Group position="right">
        <Text w="max-content" ta="right">
          {currencyFormat(record?.lastAmount || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Гүйлгээний утга",
    render: (record) => <Text w="max-content">{record?.trxDesc || "-"}</Text>,
  },
  {
    title: "Төлсөн дансны дугаар",
    render: (record) => <Text w="max-content">{record?.creditAcc?.number || "-"}</Text>,
  },
  {
    title: "Төлсөн дансны нэр",
    render: (record) => (
      <Text w="max-content">
        {record?.creditAcc?.name || "-"}{" "}
        <Text component="span" color="gray">
          ( {record?.creditAcc?.shortName || "-"} )
        </Text>{" "}
      </Text>
    ),
  },
  {
    title: "Хүлээн авсан дансны дугаар",
    render: (record) => <Text w="max-content">{record?.debitAcc?.number || "-"}</Text>,
  },
  {
    title: "Хүлээн авсан дансны нэр",
    render: (record) =>
      record?.debitAcc ? (
        <Text w="max-content">
          {record?.debitAcc?.name || "-"}{" "}
          <Text component="span" color="gray">
            ( {record?.debitAcc?.shortName || "-"} )
          </Text>{" "}
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Төлбөрийн хэрэгсэл",
    render: (record) =>
      Tag(
        paymentMethods?.find((item: IReference) => item?.code === record?.paymentMethod)?.color || "",
        paymentMethods?.find((item: IReference) => item?.code === record?.paymentMethod)?.name || "-",
      ),
  },
  {
    title: "Төлбөрийн төрөл",
    render: (record) =>
      Tag(
        transactionTypes?.find((item: IReference) => item?.code === record?.paymentType)?.color || "",
        transactionTypes?.find((item: IReference) => item?.code === record?.paymentType)?.name || record?.paymentType || "-",
      ),
  },
  {
    title: "Төлбөрийн төлөв",
    render: (record) =>
      Tag(
        repaymentStatus?.find((item: IReference) => item?.code === record?.repaymentStatus)?.color || "",
        repaymentStatus?.find((item: IReference) => item?.code === record?.repaymentStatus)?.name || record?.repaymentStatus || "-",
      ),
  },
  {
    title: "Нэхэмжлэхийн төлөв",
    render: (record) =>
      Tag(
        repaymentOverDueStatus?.find((item: IReference) => item?.code === record?.overdueStatus)?.color || "",
        repaymentOverDueStatus?.find((item: IReference) => item?.code === record?.overdueStatus)?.name || record?.overdueStatus || "-",
      ),
  },
  {
    title: "Төлбөр төлсөн",
    render: (record) => (
      <Flex align="center" gap={"xs"} w={"max-content"}>
        <Avatar src={record.actionUser.avatar} size={32} />
        <div>
          <Text>
            {record?.actionUser?.lastName ? record?.actionUser?.lastName[0] + ". " : ""}
            {record?.actionUser?.firstName || "-"}
          </Text>
        </div>
      </Flex>
    ),
  },
];
