import { Button, Center, Divider, Flex, Grid, Group, Paper, Space, Text } from "@mantine/core";
import dayjs from "dayjs";
import React, { useState } from "react";
import { SettlementApi } from "../../apis";
import { ReactComponent as Golomt } from "../../assets/golomt.svg";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { message } from "../../utils/Message";
import { Form } from "../form";
import { ColumnType, Table } from "../table";

type IForm = {
  histories: [];
};

export function AllowSettlementForm({
  onCloseModal,
  action,
  reload,
  settlementDate,
}: {
  onCloseModal: () => void;
  action: any;
  reload: any;
  settlementDate: string;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [details, setDetails] = useState(action[1]);
  const [data] = React.useState<IForm>({
    histories: [],
  });

  const columns = useHeader();

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    try {
      await SettlementApi.respond(`${action[1]?.id}`);
      message.success("Үйлдэл амжилттай.");
      await reload();
    } catch (error: any) {
      message.error(error?.message);
    }
    onCloseModal();
    setLoading(false);
  };

  return (
    <Form initialValues={data} onSubmit={onSubmit}>
      {() => {
        return (
          <>
            <Paper withBorder p={"md"} radius={"sm"}>
              <Group position="apart">
                <Golomt />
                <Text align="end" fw={600} size={"xl"}>
                  <Text underline color="violet">
                    ТТД: {details?.business?.regNumber || "-"}
                  </Text>
                  <Text color="gray.8" underline>
                    Санхүүжилтийн тооцооны хуудас
                  </Text>
                </Text>
              </Group>
              <Divider my="xl" />
              <Center>
                <Text color="gray.7">
                  Хүндэт{" "}
                  <Text component="span" color="violet">
                    {details?.financeStaff?.lastName ? details?.financeStaff?.lastName[0] + ". " : ""} {details?.financeStaff?.firstName || "-"}
                  </Text>
                </Text>
              </Center>
              <Center>
                <Text w={"820px"} align="center" color="gray.7">
                  Таны төлөөлж буй{" "}
                  <Text color="violet" component="span">
                    # {details?.business?.partner?.refCode || "-"},{" "}
                    <Text component="span" fw={600}>
                      {" "}
                      {details?.business?.partner?.businessName || "-"}
                    </Text>
                  </Text>
                  -ийн{" "}
                  <Text color="violet" component="span">
                    # {details?.business?.refCode || "-"},{" "}
                    <Text component="span" fw={600}>
                      {details?.business?.profileName || "-"}
                    </Text>
                  </Text>{" "}
                  -ийн хувьд{" "}
                  <Text color="violet" component="span">
                    {dayjs(settlementDate).format("YYYY-MM-DD")}
                  </Text>
                  -ийн байдлаар санхүүжилт эргэн төлөх{" "}
                  <Text component="span" color="violet" size="xl" fw={600}>
                    {details?.lastInvCount || "-"}
                  </Text>{" "}
                  тооны нийт{" "}
                  <Text component="span" color="violet" size="xl" fw={600}>
                    {currencyFormat(details?.lastAmount || 0)}
                  </Text>{" "}
                  төгрөгийн үлдэгдэл байна.
                </Text>
              </Center>
              <Space h={"lg"} />
              <Center>
                <Flex gap={8} my={"lg"}>
                  <Button disabled={action[0] === "details"} color="violet" variant="outline">
                    Дэлгэрэнгүй
                  </Button>
                  <Button disabled={action[0] === "details"} loading={loading} type="submit" color="violet">
                    Тооцоо зөвшөөрөх
                  </Button>
                </Flex>
              </Center>
              <Divider />
              <Text size="lg" color="gray.7" py="lg">
                Тооцоо эхлэх огноо{" "}
                <Text component="span" color="violet">
                  {dayjs(settlementDate).format("YYYY-MM") + "-1"}
                </Text>{" "}
                . Тооцоо дуусах огноо{" "}
                <Text component="span" color="violet">
                  {dayjs(settlementDate).format("YYYY-MM-DD")}
                </Text>
                .
              </Text>
              {/* <pre>{JSON.stringify(details, null, 2)}</pre> */}
              <Table pagination={false} name="requests" columns={columns} dataSource={details?.histories || []} />
              <Divider my="xl" />
              <Group position="right">
                <Text w={"500px"} color="gray.7">
                  <Grid gutter={"xs"}>
                    <Grid.Col span={6}>
                      <Text fw={700} align="end">
                        Эхний үлдэгдэл:
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Text fw={700} align="end">
                        {currencyFormat(details?.firstAmount || 0)}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Text align="end">Тухайн сард нэмэгдсэн:</Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Text align="end">{currencyFormat(details.confirmedAmount)} </Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Text align="end">Тухайн сард хасагдсан:</Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Text align="end">{currencyFormat(details.paidAmount)} </Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Text fw={700} align="end">
                        Эцсийн үлдэгдэл:
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Text fw={700} align="end">
                        {currencyFormat(details?.lastAmount || 0)}
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Text>
              </Group>
              <Space h={"lg"} />
              <Space h={"lg"} />
              <Text size={"sm"} align="center" color="gray.7" component="p">
                Голомт банк, хаяг хаяг хаяг, и-мэйл, утас гэх мэт холбоо барих хаяг
              </Text>
            </Paper>
            {/* <pre>{JSON.stringify(details, null, 2)}</pre> */}
          </>
        );
      }}
    </Form>
  );
}

const useHeader = (): ColumnType<any>[] => [
  {
    title: "Огноо",
    width: "max-content",
    align: "center",
    sorter: true,
    render: (record) => (
      <Text color="dimmed" fw={500} align="end">
        {dateTimeFormat(record.createdAt)}
      </Text>
    ),
  },
  {
    title: "Тайлбар",
    width: "max-content",
    align: "center",
    sorter: true,
    render: (record) => (
      <Text color="dimmed" fw={500}>
        {record.description}
      </Text>
    ),
  },
  {
    title: "Үнийн дүн",
    width: "max-content",
    align: "center",
    sorter: true,
    render: (record) => (
      <Group position="right">
        <Text fw={500}>{currencyFormat(record.trxAmount)}</Text>
      </Group>
    ),
  },
  {
    title: "Үлдэгдэл дүн",
    width: "max-content",
    align: "center",
    sorter: true,
    render: (record) => (
      <Group position="right">
        <Text fw={500}>{currencyFormat(record.lastAmount)}</Text>
      </Group>
    ),
  },
];
